import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const _frontmatter = {
  "title": "When Should You Polish Your UI?",
  "date": "2017-07-26",
  "promo": "grids",
  "description": "If you polish your UI at the wrong time you'll slow yourself down or ship junk. The best time optimizes for speed and impact.",
  "color": "#23f4be"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`How do you know when to polish your UI? Ever wasted a ton of time and effort polishing something that didn't even end up shipping? Or ever ship something under pressure that you weren't remotely proud of? Knowing `}<strong parentName="p">{`when to polish`}</strong>{` your UI can make a huge difference in the `}<em parentName="p">{`speed`}</em>{` and `}<em parentName="p">{`quality`}</em>{` of your work.`}</p>
    <h2>{`Polish the Right Things`}</h2>
    <p>{`Polish is anything that `}<strong parentName="p">{`decreases your users' pain or increases their delight`}</strong>{`. If you dogfood (use) your own app (you should) then you'll at least have some of your own pains & delights as a starting point for polish. But `}<a parentName="p" {...{
        "href": "/post/what-to-do-with-user-feedback/"
      }}>{`you need user feedback`}</a>{` to really know where to spend most of your polish efforts. My artist father dropped this bomb of wisdom that's stuck with me all these years:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Don't bother making changes that are just different but not better.`}</p>
    </blockquote>
    <p>{`It's completely normal for people to get hung up on tiny things. After shipping `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`Flexbox Zombies`}</a>{` I had a few people say that having to click the "Next" button so much was annoying. This wasn't a significant pain to me personally but I recognized a pattern in my feedback so I spent an hour and implemented a keyboard shortcut for it. It was a tiny fix, but it was spot on. Way more users thanked me for this little bit of polish than had complained before!`}</p>
    <p>{`When you nail this - polishing the `}<em parentName="p">{`right`}</em>{` things - it feels SO good and builds trust.`}</p>
    <h2>{`Too Early`}</h2>
    <p><img parentName="p" {...{
        "src": "/img/polish/too-early.png",
        "alt": "polish too early"
      }}></img></p>
    <p>{`If you polish too early you'll slow down your development velocity and squirrel out on tiny insignificant details. I made this mistake building Flexbox Zombies - I spent hours meticulously lining up all of the arrows' start/end positions for animation, only to realize later that I could automate all that by grabbing the coordinates from the canvas sprites directly. All that polish work was for nothing because I focused on it too soon.`}</p>
    <p>{`Watch a skilled painter like Bob Ross create a masterpiece and you'll notice that he always starts with the `}<strong parentName="p">{`broad strokes first`}</strong>{`. He'll use a giant two inch brush and sweeping strokes to lay down the basic structure of the painting - the background sky, the water, the distant mountains. He knows that adding the fine details of snow caps, shadows, and happy little trees at this stage of the painting would be a mistake. It would distract him from getting the overall composition right.`}</p>
    <p>{`Icon designer Kyle Adams applies this mindset of `}<a parentName="p" {...{
        "href": "https://kyleadams.me/easiest-color-choices/"
      }}>{`build it first, paint it later`}</a>{` to choosing colors, noticing a big spike in his creativity and speed. Deliberately delaying polish helps you prototype and build your UI a lot faster and helps prevent wasted effort.`}</p>
    <h2>{`Too Late`}</h2>
    <p><img parentName="p" {...{
        "src": "/img/polish/too-late.png",
        "alt": "polish too late"
      }}></img></p>
    <p>{`That said, if you wait too long and polish too late in the game you'll mess up your chance at making a great impression. Delaying all polish until after you get your product out the door hurts your users' trust in your ability to create great things for them. `}<strong parentName="p">{`Don't ship ugly and broken products, ever`}</strong>{`. You can shout "but... Agile!" to the backs of all your departing users all you want. Even if some of them stick around, the damage will be done - they'll always remember your first steaming pile of an attempt! New products and new features always need some level of polish before they ship.`}</p>
    <h2>{`Just Right`}</h2>
    <p><img parentName="p" {...{
        "src": "/img/polish/just-right.png",
        "alt": "polish UI"
      }}></img></p>
    <p>{`When building a UI start with the broad strokes: define the user's problem you're wanting to solve. Prototype a bunch of possible solutions quickly and freely. Don't get distracted by details, edge cases, colors, integrations. Once you decide on the best solution - hopefully with input from talking to actual users - then polish it up and ship it. But don't move on to other tasks just yet! Get feedback from your users that same day. Quickly `}<a parentName="p" {...{
        "href": "/post/what-to-do-with-user-feedback/"
      }}>{`find the patterns in the feedback`}</a>{`. You now have a unique opportunity to `}<em parentName="p">{`show`}</em>{` your users how much they mean to you. `}<strong parentName="p">{`Polish what you just shipped ASAP`}</strong>{`. Destroy the pains & frictions that surfaced from users trying out what you just created. Double down on the things that they loved. Don't put it on the backlog for a few months. This is the best time to do it: it's fresh on everyone's mind. Do it now and cut a new polish release within a day or two. Your users' minds will be blown that you're not only listening to what matters to them, but are also apparently able to execute at lightning speed.`}</p>
    <p>{`Applying polish at the right times makes it easy for users to care about what you're working on next. When Google talks about upcoming A.I. features in their apps all I can think about is how I just wish Google Photos would work with Google Inbox. It's probably low-hanging, low-priority fruit for them. Jira ticket #300248723. But for me it's pain and frustration every time I want to send my mom a cute picture of my dog Samwise.`}</p>
    <p>{`Work quickly by delaying polish while you prototype & build. Inspire trust and excitement by polishing `}<em parentName="p">{`just before`}</em>{` and especially `}<em parentName="p">{`right after`}</em>{` you ship. This is the approach I'm taking on my new Mastery Game `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{` and so far it's coming along a lot faster than my last one.`}</p>
    <h2>{`Constant Polish`}</h2>
    <p><img parentName="p" {...{
        "src": "/img/polish/polish-rhythm.png",
        "alt": "polish UI"
      }}></img></p>
    <p>{`One last note on polish: it isn't a one-time deal. I worked at a place once that did a company-wide polish effort for several weeks. It was definitely an interesting experiment, and a lot of features were improved. But after that event things went back to normal, and polish simply wasn't a priority. Just a few months later and it was as if that experiment had never happened.`}</p>
    <p>{`Polish needs to be a regular part of the team's rhythm. Imagine if every cycle/iteration/sprint/whatever looked like this:`}</p>
    <ol>
      <li parentName="ol">{`Choose which problem to tackle`}</li>
      <li parentName="ol">{`Prototype a bit and chat with users until the best solution is discovered`}</li>
      <li parentName="ol">{`Build it quickly`}</li>
      <li parentName="ol">{`Polish it up`}</li>
      <li parentName="ol">{`Ship it`}</li>
      <li parentName="ol">{`Get user feedback and polish the crap out of it`}</li>
    </ol>
    <p>{`Constant polish on the `}<strong parentName="p">{`right things`}</strong>{` - only at the `}<strong parentName="p">{`right times`}</strong>{` - is key for quickly building fantastic software that your people will love.`}</p>
    <h2>{`Challenge`}</h2>
    <p>{`Do you find yourself getting caught up on minor details at the wrong time? Make a conscious effort to not polish until right before shipping. Are your team and your users proud of what you're creating? Compare your current dev cycle to the one above. Make a change to introduce polish into your cycle - right before and immediately after shipping.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      